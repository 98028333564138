export default class ItemDistance {
  constructor () {
    ItemDistance.constructor_.apply(this, arguments)
  }

  distance (item1, item2) {}

  getClass () {
    return ItemDistance
  }

  get interfaces_ () {
    return []
  }
}
ItemDistance.constructor_ = function () {}
