export default class Puntal {
  constructor () {
    Puntal.constructor_.apply(this, arguments)
  }

  getClass () {
    return Puntal
  }

  get interfaces_ () {
    return []
  }
}
Puntal.constructor_ = function () {}
