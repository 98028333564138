export default class QuadEdgeLocator {
  constructor () {
    QuadEdgeLocator.constructor_.apply(this, arguments)
  }

  locate (v) {}

  getClass () {
    return QuadEdgeLocator
  }

  get interfaces_ () {
    return []
  }
}
QuadEdgeLocator.constructor_ = function () {}
