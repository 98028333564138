export default class Lineal {
  constructor () {
    Lineal.constructor_.apply(this, arguments)
  }

  getClass () {
    return Lineal
  }

  get interfaces_ () {
    return []
  }
}
Lineal.constructor_ = function () {}
