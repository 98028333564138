export default class PointOnGeometryLocator {
  constructor () {
    PointOnGeometryLocator.constructor_.apply(this, arguments)
  }

  locate (p) {}

  getClass () {
    return PointOnGeometryLocator
  }

  get interfaces_ () {
    return []
  }
}
PointOnGeometryLocator.constructor_ = function () {}
